<template>
    <div class="slider-blog">
        <VueSlickCarousel v-bind="settings" @afterChange="trackClick('Blog', 'Scroll_blog_slider', 'scroll')">
            <div v-for="(publication, index) in publicationsData" :key="index" class="slide-content">
                <div class="col-12">
                    <div class="blog-item">
                        <div class="img-thumb-blog"
                             v-if="publication.blog_imagen && publication.blog_imagen.public_path">
                            <img
                                loading="lazy"
                                width="248px"
                                height="167px"
                                :src="`${publication.blog_imagen.public_path}?size=mobile`"
                                :alt="publication.blog_imagen.alt"/>
                        </div>
                        <div class="blog-content-home">
                            <div class="sub-title mb-0 px-3">
                                {{ getCategoryTitle(publication) }}
                            </div>
                            <div class="blog-title" v-if="publication['title']">
                                <h4 class="reduced-text d-none d-sm-block" v-html="publication['title']"></h4>
                            </div>
                            <div class="blog-txt" v-if="publication['blurb']"
                                 v-html="publication['blurb']"/>
                            <div class="blog-read-more">
                                <p @click="goToSection(publication)">
                                    Leer más
                                    <img v-img-prefix :src="`/public-assets/arrow-right-n`" alt="arrow-right-n"
                                         class="img-fluid" width="16" height="13"/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <div v-if="enableButton" class="col-12 button-bottom text-center mt-5">
            <a href="/blog" class="btn btn-primary btn-orange" @click="trackClick('Blog', 'Ir_al_blog')">
                <span class="px-5">Ir al blog</span>
            </a>
        </div>
    </div>
</template>

<script>
import { trackClick } from "@/helpers/clickTracker";
export default {
    props: {
        publicationsData: {type: Array, default: []},
        enableButton: {type: Boolean, default: true, required: false},
        smallerCards: {type: Boolean, default: false, required: false}
    },
    data() {
        return {
            settings: {
                "dots": true,
                "infinite": false,
                "slidesToShow": 3,
                "focusOnSelect": true,
                "initialSlide": 0,
                "edgeFriction": 0.35,
                "responsive": [
                    {
                        "breakpoint": 1400,
                        "settings": {
                            "slidesToShow": 3,
                        }
                    },
                    {
                        "breakpoint": 1250,
                        "settings": {
                            "slidesToShow": 2.6,
                        }
                    },
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 2.2,
                        }
                    },
                    {
                        "breakpoint": 900,
                        "settings": {
                            "slidesToShow": 2,
                        }
                    },
                    {
                        "breakpoint": 800,
                        "settings": {
                            "slidesToShow": 1.8,
                        }
                    },
                    {
                        "breakpoint": 700,
                        "settings": {
                            "slidesToShow": 1.5,
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 1,
                            "arrows": false
                        }
                    },
                    {
                        "breakpoint": 420,
                        "settings": {
                            "slidesToShow": 1,
                            "arrows": false

                        }
                    }
                ]
            }
        }
    },
    mounted() {
        if (this.smallerCards) {
            this.settings.slidesToShow = 2
            this.settings.responsive.forEach((item, index) => {
                if (item.breakpoint >= 700) {
                    let size = item.settings.slidesToShow * 2 / 3
                    this.settings.responsive[index].settings.slidesToShow = size
                }
            })
        }
    },
    methods: {
        trackClick,
        goToSection(data) {
            trackClick('Blog', `Leer_más_${data.title}`);
            window.location.href = `/blog/${data.slug}`;
        },
        getCategoryTitle(publication) {
            let title = "Otro"
            if (publication.categoria_blog) return publication.categoria_blog
            if (publication.taxonomies.length > 0) {
                title = publication.taxonomies[0].title
            }

            return title
        }
    }
}
</script>

<style lang="scss" scoped>
.blog-txt a {
    text-decoration: none;
}

.reduced-text {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 420px) {
    .blog-item {
        width: 250px !important;
    }

    .sub-title {
        padding: 0 7px !important;
    }
}

.blog-item {
    width: 300px;
    max-width: 300px;
    margin: 1rem auto;
}

.slider-blog {
    display: block !important;
    margin: auto;
    margin-bottom: 100px;
    max-width: 80%;
}

.blog-content-home {
    display: flex;
    flex-direction: column;
}

.blog-item .blog-title {
    color: black;
    height: auto;
}

.blog-content-home h4 {
    font-size: 16px;
}

.blog-content-home .blog-txt {
    font-size: 16px;
}

.blog-content-home .sub-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
}
</style>